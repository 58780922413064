
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Stars extends Vue {
  smallStarsBoxShadow = '';
  mediumStarsBoxShadow = '';
  largeStarsBoxShadow = '';
  twinkleBoxShadow = '';

  windowHeight = 0;
  windowWidth = 0;

  showAnimation = true;
  resizeTimeout: ReturnType<typeof setTimeout> | null = null;

  created(): void {
    this.updateWindowDimensions();
    this.updateStarsBoxShadows();
    window.addEventListener("resize", this.onResize.bind(this));
  }

  public getSmallStarStyles(): any {
    return {
      "box-shadow": this.smallStarsBoxShadow,
    };
  }

  private onResize(): void {
    this.showAnimation = false;
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.updateWindowDimensions();
      this.updateStarsBoxShadows();
      this.showAnimation = true;
    }, 500);
  }

  private updateWindowDimensions(): void {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  private updateStarsBoxShadows(): void {
    let smallView = false;
    if (this.windowHeight * this.windowWidth < 300000) {
      smallView = true;
    }
    this.smallStarsBoxShadow = this.createBoxShadows(smallView ? 400 : 800);
    this.mediumStarsBoxShadow = this.createBoxShadows(smallView ? 250 : 500);
    this.largeStarsBoxShadow = this.createBoxShadows(smallView ? 100 : 200);
    this.twinkleBoxShadow = this.createBoxShadows(smallView ? 1000 : 500, true);
  }

  private randomNum(range: number): number {
    return Math.floor(Math.random() * range) + 1;
  }

  private createBoxShadows(count: number, isTwinkle = false): string {
    let output = "";
    for (let x = 0; x < count; x += 1) {
      const positionX = `${this.randomNum(this.windowWidth * 2)}px `;
      const positionY = `${this.randomNum(this.windowHeight * 2)}px `;
      let color = "rgba(0, 0, 0, 0.5)";
      if (!isTwinkle) {
        switch (this.randomNum(20)) {
          // yellow
          case 1:
            color = "#FFFF00";
            break;
          // blue
          case 2:
            color = "#60A3D9";
            break;
          // dark orange
          case 3:
            color = "#ff5100";
            break;
          // white
          default:
            color = "#FFF";
        }
      }
      const outputString = positionX + positionY + color;
      const fuzzyString = isTwinkle
        ? positionX + positionY + "5px 5px " + color
        : this.randomNum(2) === 1
        ? positionX + positionY + `${this.randomNum(4)}px 1px ` + color
        : "";
      output += outputString + (fuzzyString ? ", " + fuzzyString : "");
      if (x + 1 < count) {
        output += ", ";
      }
    }
    return output;
  }
}
