import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-095c5b7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  href: "https://github.com/sarahtennis",
  target: "_blank"
}
const _hoisted_3 = {
  href: "https://www.linkedin.com/in/sarahtennis/",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typing = _resolveComponent("Typing")!
  const _component_GithubIcon = _resolveComponent("GithubIcon")!
  const _component_LinkedinIcon = _resolveComponent("LinkedinIcon")!
  const _component_EmailIcon = _resolveComponent("EmailIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Typing, { onDone: _ctx.onTypingFinished }, null, 8, ["onDone"]),
    _createElementVNode("div", {
      class: _normalizeClass(["icon-container", { hidden: !_ctx.typingFinished }])
    }, [
      _createElementVNode("a", _hoisted_2, [
        _createVNode(_component_GithubIcon)
      ]),
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_LinkedinIcon)
      ]),
      _createVNode(_component_EmailIcon, { onClick: _ctx.onEmailIconClicked }, null, 8, ["onClick"])
    ], 2)
  ]))
}