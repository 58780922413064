
import { Options, Vue } from 'vue-class-component';
import Card from './components/Card.vue';
import Stars from './components/Stars.vue';

@Options({
  components: {
    Stars,
    Card
  },
})
export default class App extends Vue {}
