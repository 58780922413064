
import { Options, Vue } from "vue-class-component";
import {useToast} from 'vue-toast-notification';
import Typing from "./Typing.vue";
import EmailIcon from "../assets/Email.vue";
import GithubIcon from "../assets/Github.vue";
import LinkedinIcon from "../assets/Linkedin.vue";

@Options({
  components: {
    Typing,
    EmailIcon,
    GithubIcon,
    LinkedinIcon,
  },
})
export default class Card extends Vue {
  typingFinished = false;

  onTypingFinished() {
    this.typingFinished = true;
  }

  onEmailIconClicked() {
    navigator.clipboard.writeText('sarah.n.tennis@gmail.com');
    const toast = useToast();
    toast.success('Copied email to clipboard');
  }
}
