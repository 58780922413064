
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Typing extends Vue {
  letterState = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  created() {
    let typingPathIndex = 0;
    const typingInterval = setInterval(() => {
      this.letterState[typingPathIndex] = true;
      this.letterState = [...this.letterState];
      typingPathIndex++;
      if (typingPathIndex > 29) {
        clearInterval(typingInterval);
        this.$emit('done');
      }
    }, 150);
  }

  // Returns opacity value for visiblity
  public getIsVisible(index: number): string {
    if (index > 29 || index < 0) return "0";
    return this.letterState[index] ? "1" : "0";
  }
}
