import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stars-container" }
const _hoisted_2 = { class: "twinkle1" }
const _hoisted_3 = { class: "twinkle2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["small1", { 'animation-long': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "small-stars",
        style: _normalizeStyle(_ctx.getSmallStarStyles())
      }, null, 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["medium3", { 'animation-medium': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "medium-stars",
        style: _normalizeStyle({ boxShadow: _ctx.mediumStarsBoxShadow })
      }, null, 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["large5", { 'animation-short': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "large-stars",
        style: _normalizeStyle({ boxShadow: _ctx.largeStarsBoxShadow })
      }, null, 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["small2", { 'animation-long': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "small-stars",
        style: _normalizeStyle(_ctx.getSmallStarStyles())
      }, null, 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["medium4", { 'animation-medium': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "medium-stars",
        style: _normalizeStyle({ boxShadow: _ctx.mediumStarsBoxShadow })
      }, null, 4)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["large6", { 'animation-short': _ctx.showAnimation }])
    }, [
      _createElementVNode("div", {
        class: "large-stars",
        style: _normalizeStyle({ boxShadow: _ctx.largeStarsBoxShadow })
      }, null, 4)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "twinkle-stars",
        style: _normalizeStyle({ boxShadow: _ctx.twinkleBoxShadow })
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "twinkle-stars",
        style: _normalizeStyle({ boxShadow: _ctx.twinkleBoxShadow })
      }, null, 4)
    ])
  ]))
}